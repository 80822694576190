@import "@amzn/ring-safe-web/styles/font";
@import "@amzn/ring-safe-web/styles/color";

:global(#header) :global(#language-selector-root) {
  .language-selector {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 69px;
    color: theme-color(light, content-base);
    font-weight: 400;
    cursor: pointer;

    @include get-font-size(body-normal);

    &__list {
      position: absolute;
      right: -80px;
      box-sizing: border-box;
      width: 200px;
      margin-top: 4px;
      overflow: hidden;
      font-weight: 400;
      background: white;
      border: 1px solid color(tertiary-700);
      border-radius: 4px;
      box-shadow: 0 4px 16px rgb(0 0 0 / 15%);

      @include get-font-size(body-normal);
    }

    &__list-item {
      height: 40px;
      padding: 8px 16px;
      overflow: hidden;
      color: color(neutral-500);
      text-align: left;
      background: white;
      cursor: pointer;
      transition: 0.3s;
    }

    &__list-item:hover {
      background: var(--light);
    }

    &__list-item_checked {
      color: var(--default);
    }

    &__button-icon {
      width: 18px;
      height: 18px;
      margin-bottom: 4px;
      margin-left: 6px;
      border: 1px solid color(tertiary-500);
      border-radius: 50%;
      pointer-events: none;
    }

    &__item-label {
      font-family: $default-font;
    }

    :global(.rcl__dropdown__item-icon) {
      margin-right: 16px;
    }

    :global(.fi) {
      width: 24px;
      height: 24px;
      background-size: 100%;
      pointer-events: none;
    }

    @mixin field {
      box-sizing: border-box;
      height: 30px;
      background: white;
      border: none;
      transition: border-color 0.3s;
    }

    &__dropdown {
      width: 40px;
      min-width: 40px;
    }

    &__dropdown-button {
      box-sizing: border-box;
      width: 45px;
      height: 50px;
      margin-top: 10px;
      padding: 3px;
      background: white;
      border: none;
    }

    &__label-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      pointer-events: none;
    }

    &__dropdown_open {
      border-color: theme-color(light, primary-base);
      border-width: 2px;
      outline: none;
    }

    &__header-icon-label {
      display: block;
      font-weight: 400;
      font-size: 12px;
      font-family: $default-font;
      pointer-events: none;
    }

    &__dropdown-button_open {
      outline: none;
    }

    &__field {
      display: flex;
    }

    &_disabled &__field {
      pointer-events: none;
    }

    &_disabled &__dropdown-button {
      color: theme-color(light, tertiary-border-deep);
      font-weight: 300;
      background-color: theme-color(light, tertiary-background);
      border-color: theme-color(light, tertiary-border);
    }

    div[datatype="dropdown-item-icon"] {
      margin-right: 16px;
    }

    &__dropdown-button svg {
      margin-top: 20px;
    }
  }
}
