@import "@amzn/ring-safe-web/styles/color";
@import "@amzn/ring-safe-web/styles/font";

.appointment-page {
  $left-block-width: 462px;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  min-height: 100%;
  margin: 0 var(--container-margin);
  padding: 0 var(--container-pagging);
  background:
    linear-gradient(
      90deg,
      white $left-block-width,
      color(tertiary-300) $left-block-width,
      color(tertiary-300) 100%
    );

  &__notifications {
    margin-right:
      calc(
        (min(
          $header-inner-width,
          100vw - var(--container-pagging) * 2
        ) - min($header-width, 100vw)) / 2
      );
  }

  &__header {
    margin: 0 var(--container-margin);
    padding: 0 var(--container-pagging);
    background: white;
    border-bottom: 1px solid color(tertiary-500);
  }

  &__page {
    display: flex;
    flex-grow: 1;
    height: 100%;
  }

  &__appointment {
    margin: 0 0 8px;
  }

  &__left-block {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: $left-block-width;
    min-width: $left-block-width;
    padding: 32px 40px 0 0;
    background: white;
    border-right: 1px solid color(tertiary-500);
  }

  &__right-block {
    position: relative;
    display: flex;
    flex-grow: 1;
    gap: 58px;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
  }

  &__illustration {
    margin: 0 0 0 42px;
  }

  &__title {
    color: color(neutral-500);
    white-space: pre-wrap;

    @include get-font-size(heading-normal);
  }

  &__subtitle {
    margin: 24px 0 8px;
    color: color(neutral-300);

    @include get-font-size(body-normal);
  }

  &__appointments {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__button {
    width: fit-content;
  }

  @media (max-width: 1399px) {
    &__illustration {
      display: none;
    }
  }
}
