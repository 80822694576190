@import "@amzn/ring-safe-web/styles/color";
@import "@amzn/ring-safe-web/styles/font";

.mobile-appointment-page {
  flex-grow: 1;
  box-sizing: border-box;
  height: 100%;
  margin: 0 var(--container-margin);
  background: color(tertiary-300);

  &__header {
    border-bottom: 1px solid color(tertiary-500);
  }

  &__appointment {
    padding: 24px;
    background: white;
  }

  &__check-circle {
    width: 48px;
    height: 48px;
    margin: 18px 0 8px;
  }

  &__title {
    color: color(neutral-500);
    white-space: pre-wrap;

    @include get-font-size(heading-normal);
  }

  &__subtitle {
    margin: 24px 0 8px 24px;
    color: color(neutral-300);
    font-weight: 500;

    @include get-font-size(body-normal);
  }

  &__appointments {
    flex-grow: 1;
    margin: 0 0 8px;
    padding: 0;
    list-style: none;
    background: color(tertiary-300);
    border-top: 1px solid color(tertiary-500);
  }

  &__button {
    width: fit-content;
  }

  &__mobile-notifications {
    display: block;
    margin: 32px;
  }
}
