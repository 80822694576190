@import "@amzn/ring-safe-web/styles/font";
@import "@amzn/ring-safe-web/styles/color";
@import "src/animation";

.appointment {
  &_loading {
    pointer-events: none;
    touch-action: none;
  }

  &__title {
    min-height: 24px;
    margin: 0 0 2px;
    overflow: hidden;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-overflow: ellipsis;
  }

  &__content {
    width: 100%;
  }

  &__subtitle {
    min-height: 18px;
    margin: 0;
    color: color(tertiary-700);
    font-size: 12px;
    line-height: 18px;
  }

  &_loading &__title {
    @include skeleton;

    width: 90%;
  }

  &_loading &__subtitle {
    @include skeleton;

    width: 60%;
  }
}
