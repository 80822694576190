@import "@amzn/ring-safe-web/styles/color";
@import "@amzn/ring-safe-web/styles/font";
@import "src/animation";
@import "src/layout";

.appointment-card {
  box-sizing: border-box;
  min-width: 480px;

  &_loading {
    pointer-events: none;
    touch-action: none;
  }

  @media (max-width: $header-mobile) {
    min-width: auto;
  }

  &__header_empty > h1 {
    @include skeleton;
  }

  &__header_empty > h2 {
    @include skeleton;

    width: 80%;
  }

  &__icon {
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    margin: 0 8px 0 0;
    fill: color(primary-500);
  }

  &__button {
    font-size: inherit;
  }

  &__button_type_neutral:global(.rcl__button) {
    color: color(neutral-300); // we use non-standard color
  }

  &__button_type_neutral:global(.rcl__button.rcl__button_disabled) {
    color: color(neutral-200); // we use non-standard color
  }

  &__list-title {
    margin: 0 0 8px;
    color: color(neutral-300);
    font-weight: 500;

    @include get-font-size(body-small);
  }

  &__bold {
    font-weight: 500;
  }

  &__li {
    position: relative;
    margin: 0 0 12px;
    padding: 0 0 0 32px;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
