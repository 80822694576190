@import "@amzn/ring-safe-web/styles/font";
@import "@amzn/ring-safe-web/styles/color";
@import "src/layout";
@import "src/animation";

.welcome-page {
  width: $header-inner-width;
  max-width: 100%;
  margin: 0 auto;
  padding: 52px 0 0;
  color: color(neutral-500);

  &__notifications:global(.rcl__notification__container) {
    position: absolute;
    top: 72px;
    max-width: $header-width;
    margin: auto;

    div {
      width: 100%;
      max-width: $header-inner-width;
      margin: auto;
    }
  }

  &__services {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    margin: 62px 0 0;
  }

  &__service {
    flex-grow: 1;
    max-width: 80%;
    margin: 0 20px 40px;
  }

  &__header {
    margin-top: 38px;
    color: color(neutral-500);
    font-weight: 500;
    text-align: center;

    @include get-font-size(body-normal);
  }

  &__title {
    margin-bottom: 2px;
  }

  &_loading &__title {
    width: 50%;
  }

  &_loading &__subtitle {
    width: 30%;
  }

  &_loading &__header {
    width: 100%;
    height: 38px;
  }

  &_loading &__button {
    height: 50px;
  }

  &_loading &__header,
  &_loading &__title,
  &_loading &__subtitle {
    @include skeleton;
  }

  @media (max-width: $layout-s-max) {
    & {
      margin-top: 0;

      &__services {
        margin-top: 50px;
      }
    }
  }

  @media (min-width: $layout-m-min) {
    &__service {
      max-width: 247px;
    }
  }

  @media (min-width: $layout-xl-min) {
    &__service {
      max-width: 280px;
    }
  }
}
