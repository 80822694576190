@import "@amzn/ring-safe-web/styles/color";
@import "@amzn/ring-safe-web/styles/font";
@import "src/layout";

.katal {
  position: relative;
  max-width: 100%;
  font-family: $default-font !important;

  kat-table kat-table-head kat-table-cell {
    padding: 0;
  }

  .common-cal-katal {
    width: auto;
    max-width: 100%;
    margin-top: 0;

    .cal-table .cell-body-inner {
      width: 32px;
      height: 32px;
      padding: 0;
      line-height: 32px;
    }

    .weekday-row {
      .cal-cell {
        height: 32px;
        padding: 0;
        line-height: 32px;
      }

      .cell-body {
        @include get-font-size(body-small);

        .cell-body-inner {
          height: 34px;
          font-weight: 400;
        }
      }
    }

    .month-days-week {
      .cell-body {
        padding: 4px 0 8px;

        @media (max-width: 580px) {
          padding: 16px 0 8px;
        }

        .month-day {
          font-size: 14px;
        }

        .cell-body-inner:focus {
          padding: 0;
          background: inherit;
          background-color: color(primary-200);
          border: none;
        }

        .cell-body-inner.selected {
          color: white;
          background: color(primary-500);
          border: none;
          transition: 0.3s;
        }
      }

      .cell-body.disabled .cell-body-inner {
        color: color(tertiary-700) !important;
      }

      kat-table-cell {
        padding: 0;
        border: none;
      }
    }

    .section-cal-days .cal-days-header .month-name {
      color: color(neutral-500);
      font-weight: 400;

      @include get-font-size(body-large-bold);

      @media (max-width: $layout-mobile) {
        padding-left: 10px;
      }
    }

    .section-cal-slots {
      @media (max-width: $layout-mobile) {
        width: auto;
        margin: 0 -10px;
      }

      .cal-slot {
        width: 23%;
        margin: 0 0 10px;
        padding: 8px 16px;
        border: 1px solid color(tertiary-700);

        @media (max-width: 580px) {
          width: 31.33%;
          padding: 12px 16px;
        }

        @media (max-width: $layout-mobile) {
          position: relative;
          box-sizing: border-box;
          width: 98%;
          margin: 0;
          padding: 16px 24px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          border-color: color(tertiary-500);
          border-style: solid none none;
          border-radius: 0;

          &::after {
            position: absolute;
            right: 24px;
            width: 18px;
            height: 18px;
            margin-top: 3px;
            background: url("../../static/icons/chevron.svg");
            content: "";
          }
        }
      }

      .cal-slot.selected,
      .cal-slot.selected:focus {
        color: white;
        background: color(primary-500);
        border-color: color(primary-500);
        transition: 0.3s;

        &::after {
          filter: brightness(10);
        }
      }

      .cal-slot.disabled {
        // https://ring.slack.com/archives/C031S8KH95W/p1657258841918559?thread_ts=1657195665.909129&cid=C031S8KH95W
        display: none;

        // TODO: figure out why the style below is needed?
        // &:first-child {
        //   display: block; // to ensure that at least one slot is visible
        // }

        color: color(tertiary-700);
        background: color(tertiary-300);
        border-color: color(tertiary-500);
      }
    }

    .cal-showmore-days {
      background: white;
      border-top: 1px solid color(tertiary-500);
      border-radius: 0 0 4px 4px;

      @media (max-width: 580px) {
        height: 52px;
      }

      .showmore-body {
        display: flex;
        height: inherit;

        .navigation-icons {
          display: flex;
          gap: 8px;
          align-items: center;
          margin-right: 32px;
        }
      }
    }
  }

  kat-table-head,
  kat-table thead {
    line-height: 32px;
  }

  .circle {
    background-color: color(primary-500) !important;
  }

  .cal-slot-header {
    display: none;
  }

  .st-mname {
    display: none !important;
  }

  .aok-hidden {
    display: none;
  }

  .section-cal-days {
    border: 1px solid color(tertiary-500);
    border-radius: 4px;
    box-shadow: none;
  }

  .section-cal-header {
    display: none;
  }

  .weekday-name {
    position: relative;
    color: color(neutral-300);

    @include get-font-size(body-small);
  }

  .cal-days-header {
    height: 42px;
  }

  .cal-slot-list {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    margin-right: -2%;
  }

  .cal-slot .selected {
    border-color: color(primary-500) !important;
  }

  .showmore-label {
    display: none;
  }

  kat-icon[name="chevron-left"] {
    display: block;
    width: 14px;
    height: 14px;

    &::after {
      position: absolute;
      width: 14px;
      height: 14px;
      background: url("../../static/icons/chevron.svg");
      transform: rotate(180deg);
      content: "";
    }
  }

  kat-icon[name="chevron-right"] {
    display: block;
    width: 14px;
    height: 14px;

    &::after {
      position: absolute;
      width: 14px;
      height: 14px;
      background: url("../../static/icons/chevron.svg");
      content: "";
    }
  }
}
