@import "reset-css";
@import "@amzn/ring-safe-web/styles/index.global";
@import "layout";

body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  color: color(neutral-500);
  background: color(tertiary-500);
  text-rendering: optimizelegibility;

  @include scale(1.3);
  @include scale(1.5);

  &.has-banner::after {
    content: none !important;
  }
}

#header {
  position: absolute !important;
  right: 0;
  left: 0;
  width: 100%;
  max-width: $header-width;
  margin: 0 auto;
}

#root {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: color(neutral-500);
}

h1 {
  margin: 0;
  padding: 0;
  color: color(neutral-300);
  font-weight: 500;

  @include get-font-size(heading-normal);
}

h2 {
  margin: 0;
  padding: 0;
  color: color(neutral-500);
  font-weight: 500;

  @include get-font-size(heading-normal);
}

@media (max-width: $layout-s-max) {
  h1 {
    color: color(neutral-500);
  }

  h2 {
    font-weight: 300;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
  }
}

#root-content {
  --container-pagging: 40px;
  --container-margin: -40px;

  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  width: 100%;
  max-width: $header-width;
  min-height: 100%;
  margin: 0 auto;
  padding: $header-height var(--container-pagging) 0;
  background: white;

  @media (max-width: $header-inner-width) {
    --container-pagging: 24px;
    --container-margin: -24px;
  }
}

.consent-banner {
  position: static;
  width: $header-width !important;
  min-height: 76px;
  margin: 0 auto;
}

.fi::before {
  content: none;
}
