@import "src/layout";

.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: flex-end;
  box-sizing: border-box;
  width: 100%;
  max-width: $header-width;
  min-height: $footer-height;
  margin: auto;
  padding: 16px 40px;
  background-color: white;
  box-shadow: 0 -1px 8px rgb(0 0 0 / 15%);

  :global(.rcl__button) {
    min-width: 272px;
  }

  @media (max-width: 650px) {
    & {
      padding: 16px 24px;

      :global(.rcl__button) {
        width: 100%;
      }
    }
  }
}
