@import "@amzn/ring-safe-web/styles/color";
@import "src/animation";

.appointment-mobile {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  padding: 16px 24px;
  background: white;
  border-top: 1px solid color(tertiary-500);
  border-bottom: 1px solid color(tertiary-500);

  &__title {
    min-height: 24px;
    margin: 0 0 2px;
    color: color(neutral-500);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &__header {
    flex-grow: 1;
    margin: 0 16px;
  }

  &__icon {
    width: 32px;
    min-width: 32px;
    height: 32px;
    fill: color(primary-500);
  }

  &__subtitle {
    margin: 0;
    color: color(neutral-300);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  &__cancel {
    margin: 0;
    color: color(neutral-200);
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-decoration: underline;
    cursor: pointer;
  }

  &_loading &__title {
    @include skeleton;
  }
}
