@import "@amzn/ring-safe-web/styles/font";
@import "@amzn/ring-safe-web/styles/color";

.new-device-otp-form {
  display: flex;
  flex-direction: column;

  &__header {
    margin: 0 0 24px;
  }

  &__agreement {
    margin-top: 16px;
    color: color(neutral-500);
    font-weight: 300;

    @include get-font-size(body-small);
  }

  &__terms-of-service {
    margin: 16px 0 0;
    color: color(neutral-500);
    font-weight: 300;

    @include get-font-size(body-small);
  }

  &__button {
    margin: 16px 0 24px;
  }
}
