@import "@amzn/ring-safe-web/styles/color";
@import "src/layout";

.new-device-otp-modal {
  &__icon {
    fill: color(primary-500);
  }

  &__content {
    box-sizing: border-box;
    width: 435px;

    @media (min-width: $layout-l-min) {
      width: 512px;
    }
  }

  &__sms-form {
    width: inherit;
    margin-bottom: 24px;
    padding: 0;
    border: none;
  }
}
