.mobile-calendar-page {
  &__calendar-container {
    box-sizing: border-box;
    width: 100vw;
    margin: 0 -24px;
    padding: 0 10px;
  }

  &__calendar {
    position: relative;
    width: 100%;
  }

  &__link {
    display: none;
    margin: -12px 0 12px;
  }

  &__link_visible {
    display: block;
  }

  .calendar__slots {
    display: none;
  }

  &_slots &__calendar {
    padding: 0;
  }

  :global(.section-cal-days) {
    display: block;
  }

  :global(.section-cal-slots) {
    display: none;
  }

  &_slots :global(.section-cal-days) {
    display: none;
  }

  &_slots :global(.section-cal-slots) {
    display: block;
  }
}
