@import "src/layout";

.account-verification-page {
  align-items: center;
  justify-content: space-between;
  margin-top: 46px;

  &__illustration {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }

  &__svg {
    position: relative;
    left: 10%;
    width: 100%; // crucial for safari
    max-width: 400px;
  }

  &__form {
    height: 100%;
    min-height: 520px;
    background: white;
  }

  &__image {
    width: 878px;
    margin: auto;
  }

  @media (max-width: 970px) {
    justify-content: center;
    margin-top: 0;

    &__illustration {
      display: none;
    }
  }
}
