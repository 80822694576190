@import "@amzn/ring-safe-web/styles/color";
@import "@amzn/ring-safe-web/styles/font";

.title {
  &__h1 {
    color: color(neutral-500);
    font-weight: 500;

    @include get-font-size(heading-normal);
  }

  &__h2 {
    color: color(neutral-500);
    font-weight: 300;

    @include get-font-size(body-normal);
  }
}
