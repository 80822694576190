@import "@amzn/ring-safe-web/styles/color";
@import "@amzn/ring-safe-web/styles/font";
@import "src/animation";
@import "src/layout";

.calendar {
  max-width: 100%;
  transition: 0.6s;

  &__container {
    position: relative;
    width: 100%;
  }

  &_skeleton {
    position: absolute;
    width: 100%;
    margin-top: 0;
    background: white;
    opacity: 1;
    pointer-events: none;
    touch-action: none;
  }

  &__slots {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    margin: 20px -2% 0 0;
  }

  &__slot {
    box-sizing: border-box;
    width: 23%;
    height: 36px;
    margin: 0 0 10px;
    padding: 8px 16px;
    border: 1px solid color(tertiary-700);

    @media (max-width: 580px) {
      width: 31.33%;
      height: 44px;
    }

    @media (max-width: $layout-mobile) {
      box-sizing: border-box;
      width: 98%;
      height: 57px;
      margin: 0;
      text-align: left;
    }
  }

  &_skeleton &__slot {
    @include skeleton;
  }

  &_hidden {
    opacity: 0;
  }

  &__table {
    width: 100%;
    padding: 12px 0 0;
    text-align: center;
    table-layout: fixed;
    border: 1px solid color(tertiary-500);
    border-radius: 4px;
    border-spacing: 0;

    @include get-font-size(body-normal);
  }

  &__body {
    padding: 0 35px;
  }

  &__month {
    position: relative;
    padding: 14px 0 10px;
    color: color(neutral-500);
    font-weight: 400;

    @include get-font-size(body-large-bold);

    @media (max-width: $layout-mobile) {
      padding: 14px 0 10px 18px;
    }
  }

  &__week-day {
    padding: 0;
    color: color(neutral-300);
    font-weight: 400;

    @include get-font-size(body-small);

    @media (max-width: $layout-mobile) {
      font-size: 0;

      &::first-letter {
        @include get-font-size(body-small);
      }
    }
  }

  &__day {
    box-sizing: border-box;
    padding: 14px 0 6px;

    @include get-font-size(body-small);

    @media (max-width: 580px) {
      padding: 22px 0 10px;
    }
  }

  &__footer {
    display: block;
    height: 40px;
    padding: 6px 0 0;

    @media (max-width: 580px) {
      height: 48px;
      margin: 0;
    }
  }

  &_visible {
    opacity: 1;
  }
}
