@import "@amzn/ring-safe-web/styles/color";
@import "@amzn/ring-safe-web/styles/font";
@import "src/layout";

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 64px;
  text-align: center;

  &__h1 {
    margin: 0;
    padding: 18px 0 16px;
    color: color(neutral-500);
    font-weight: 500;

    @include get-font-size(body-normal);

    @media (max-width: $layout-s-max) {
      color: color(neutral-300);
    }
  }
}
