@import "@amzn/ring-safe-web/styles/color";

@mixin skeleton {
  color: transparent;
  white-space: pre;
  border-radius: 4px;
  animation: skeleton 2s ease-out infinite;
  user-select: none;
}

@keyframes skeleton {
  0% {
    background: color(neutral-100);
    opacity: 0.7;
  }

  50% {
    background: color(neutral-50);
  }

  100% {
    background: color(neutral-100);
    opacity: 0.7;
  }
}

@mixin appearance-from-top {
  animation: appearance-from-top 0.4s ease-in-out;
}

@keyframes appearance-from-top {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@mixin disappearance-to-right {
  animation: disappearance-to-right 0.4s ease-in-out forwards;
}

@keyframes disappearance-to-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}
