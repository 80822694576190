@import "src/layout";

.page {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: $header-inner-width;
  margin: 0 auto;

  &__content {
    display: flex;
    flex-wrap: wrap;
  }

  &__illustration {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: center;
    height: 100%;

    svg {
      width: 100%; // crucial for safari
    }

    @media (max-width: $layout-s-max) {
      display: none;
    }
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 100%;
    margin: auto;

    @media (max-width: $layout-m-max) {
      width: 86%;
    }
  }

  &__title {
    width: 100%;
    margin: 24px 0 32px;

    @media (max-width: $layout-s-max) {
      margin: 24px 0;
    }
  }
}
