@import "@amzn/ring-safe-web/styles/color";
@import "@amzn/ring-safe-web/styles/font";

.add-to-calendar {
  &__button:global(.rcl__dropdown__button) {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    font-weight: 500;
    background: color(tertiary-300);
    border-style: none;
  }

  &_view_mobile &__button {
    width: 100%;
    padding: 20px 24px;
    background: white;
    border-color: color(tertiary-500);
    border-style: solid none;
    border-radius: 0;
  }

  &__icon {
    width: 20px;
    margin-right: 14px;
    pointer-events: none;
    fill: color(primary-500);
  }

  &_view_mobile &__icon {
    width: 32px;
    margin-right: 16px;
  }

  &__title {
    pointer-events: none;
  }

  &__dropdown {
    margin-top: 10px;
  }

  &_view_mobile &__dropdown {
    border-color: color(tertiary-500);
    border-radius: 0;
    box-shadow: none;
  }

  &__link {
    text-decoration: none;
  }
}
