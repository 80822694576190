@import "@amzn/ring-safe-web/styles/font";
@import "@amzn/ring-safe-web/styles/color";

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: -90px;
  text-align: center;

  &__illustration {
    width: 530px;
    max-width: 100%;
  }

  &__title {
    color: color(neutral-500);
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
  }

  &__subtitle {
    margin-bottom: 24px;
    color: color(neutral-500);
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -12px;
  }

  &__button {
    width: 240px;
    margin: 0 12px 12px 0;
  }

  @media (max-width: 650px) {
    margin-top: 0;

    &__button {
      width: 80%;
    }
  }
}
