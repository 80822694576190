@use "sass:math";

$layout-xxl-min: 1920px;
$layout-xl-min: 1420px;
$layout-l-min: 1280px;
$layout-m-max: $layout-l-min - 1px;
$layout-m-min: 768px;
$layout-s-max: $layout-m-min - 1px;
$layout-mobile: 460px;
$layout-s-min: 320px;
$header-inner-width: 1318px;
$header-width: 1600px;
$header-height: 72px;
$header-mobile: 1025px;
$footer-height: 82px;

@mixin scale($number) {
  @supports (transform: scale($number)) and (width: calc(-50% / $number)) {
    @media (min-width: $header-width * $number) {
      $k: math.div($number - 1, 2 * $number);

      #header {
        transform: scale($number) translateY($header-height * $k);
      }

      #root {
        max-height: calc(100% / $number);
      }

      #root-content {
        transform: scale($number) translateY(100% * $k);
      }

      .rse__footer {
        transform: scale($number) translateY(-1 * $footer-height * $k);
      }

      /* Modal should not be nested in a element with a transform property, because of
       * Any element with transform, filter or perspective property will act as
       * a containing block to all its descendants, including the elements whose position is set to fixed
       * https://dev.to/salilnaik/the-uncanny-relationship-between-position-fixed-and-transform-property-32f6
       */
      .rse__modal {
        transform: scale($number);
      }

      .consent-banner {
        transform: scale($number) translateX(calc(-50% / $number));
      }
    }
  }
}
