@import "src/layout";
@import "@amzn/ring-safe-web/styles/color";

.contact-page {
  &__form {
    min-width: 50%;
    max-width: 604px;

    @media (max-width: $layout-m-min) {
      flex-grow: 1;
    }
  }

  &__image {
    margin-top: -64px;

    @media (max-width: $header-mobile) {
      display: none;
    }
  }

  &__agreement {
    margin: auto 16px auto auto;
    color: color(neutral-300);
  }

  &__agreement-link {
    white-space: nowrap;
  }
}
