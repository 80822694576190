@import "src/layout";

.app {
  @media (max-width: $layout-s-max) {
    &__notifications {
      margin: 0 -24px;
    }
  }

  &_fade_in {
    animation: 0.4s fade-in forwards;
  }

  &_fade_out {
    animation: 0.4s fade-out forwards;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
      transform: translate(0, 0);
      opacity: 1;
    }

    to {
      transform: translate(-20px, 0);
      opacity: 0;
    }
  }
}
