@import "@amzn/ring-safe-web/styles/color";
@import "src/layout";

.calendar-page {
  &__image {
    align-self: flex-start;
    justify-self: center;
    width: 320px;
    margin: -80px auto 0;
  }

  &__calendar {
    max-width: 640px;
  }

  @media (max-width: $layout-m-max) {
    &__image {
      max-width: 270px;
    }
  }

  @media (max-width: 1024px) {
    &__image {
      display: none;
    }
  }
}
