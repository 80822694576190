@import "@amzn/ring-safe-web/styles/color";
@import "@amzn/ring-safe-web/styles/font";
@import "src/layout";

.sms-verification-form {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 468px;
  padding: 40px 48px;
  border: 1px solid color(tertiary-500);
  border-radius: 4px;

  &__link {
    margin-right: auto;
  }

  &__header {
    color: color(neutral-500);
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
  }

  &__input input {
    width: 100%;
  }

  &__input {
    margin: 24px 0 0;
  }

  &__notification {
    margin: -8px 0 16px;
  }

  &__phone-container {
    margin: 8px 0 0;
    color: color(neutral-500);
    font-weight: 300;

    @include get-font-size(body-normal);
  }

  &__resend-label {
    margin: 0 0 16px;
    color: color(neutral-300);
    font-weight: 300;
    white-space: break-spaces;
  }

  &__resend-button {
    margin: 0 auto 0 0;
  }

  &__phone {
    display: flex;
    align-items: center;
    height: 24px;
    margin: 8px 0 16px;
    color: color(neutral-500);
    font-weight: 500;

    @include get-font-size(body-normal);
  }

  &__button {
    margin: 24px 0;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 40px 0;
    border: none;
  }
}
