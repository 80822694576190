@import "@amzn/ring-safe-web/styles/color";

.person-details-form {
  display: flex;
  flex-direction: column;

  &__button {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &__agreement {
    color: color(neutral-300);
    font-weight: 300;
  }

  &__first-name {
    margin-bottom: 16px;
  }

  &__last-name {
    margin-bottom: 16px;
  }

  &__phone {
    margin-bottom: 8px;
  }

  &__first-name input,
  &__last-name input,
  &__phone input {
    width: 100%;
  }
}
